.product {
    width: 300px;
    padding: 1rem;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0,4);
    margin: 8px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-style: solid;
    border-width: 2px;
    border-radius: 8px;
    border-color: rgb(0, 9, 74);
}

.product > img {
    height: 170px;
    border-radius: 10px;
    border-style: solid;
    border-width: 0.2px;
    border-color: rgb(0, 9, 74);
}

.product__info > p.info__name {
    padding-top: 8px;
}

.product__info > p {
    margin-bottom: 8px;
}

.info__price {
    font-weight: bold;
}

.info__name {
    font-size: 1rem;
    overflow: hidden;
}

.info__description {
    font-size: 0.8rem;
}

.info__button {
    display: block;
    width: 100%;
    text-decoration: none;
    text-align: center;
    color: #171717;
    background: #f4f4f4;
    padding: 8px 16px;
    border: 1px solid #171717;
    font-size: 1rem;
}

.info__button:hover {
    background: #171717;
    color: #f4f4f4;
}

@media (max-width: 1232px)  {
    .product {
        width: 360px;
    }
}

@media (max-width: 1115px)  {
    .product {
        width: 330px;
    }
}

@media (max-width: 1028px)  {
    .product {
        width: 300px;
    }
}

@media (max-width: 950px)  {
    .product {
        width: 400px;
    }
}

@media (max-width: 830px)  {
    .product {
        width: 230px;
    }
}

@media (max-width: 700px)  {
    .product {
        width: 290px;
    }
}

@media (max-width: 630px)  {
    .product {
        width: 450px;
    }
}

@media (max-width: 500px)  {
    .product {
        width: 350px;
    }
}

@media (max-width: 400px)  {
    .product {
        width: 300px;
    }
}