.navbar {
    width: 100%;
    height: 110px;
    background-color: #141415;
    /* background: linear-gradient(88deg, #000e56 0%, #152da7 100%); */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 1rem;
    z-index: 50;
}

.inteli > a {
    text-decoration: none;
    color: #f4f4f4;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 3rem;
    cursor: pointer;
}

.tech > a {
    text-decoration: none;
    color: #00d5ff;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 3rem;
    cursor: pointer;
}

.navbar__links {
    display: flex;
    list-style: none;
    align-items: center;
}

.navbar__links > li {
    padding-left: 1.5rem;
}

.navbar__links > li > a {
    text-decoration: none;
    color: #f4f4f4;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
}

.navbar__links > li > a > span {
    display: flex;
    align-items: center;
    margin-left: 8px;
}

.cart__link {
    background: #333;
    padding: 10px;
    border-radius: 8px;
}

.cart__link:hover {
    background: #dd219e;
    color: #f4f4f4;
}

.cartlogo__badge {
    width: 30px;
    height: 30px;
    background: #f4f4f4;
    border-radius: 50%;
    margin-left: 8px;
    color: #171717;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1rem;
}

.hamburger__menu {
    width: 30px;
    height: 30px;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.hamburger__menu > div {
    width: 100%;
    height: 3px;
    background: #f4f4f4;
}

.hamburger__menu:hover > div {
    background: #dd219e;
}

@media (max-width: 960px) {
    .navbar__links {
        display: none;
    }

    .hamburger__menu {
        display: flex;
    }
}

@media (max-width: 500px) {
    .navbar__logo h2 {
        font-size: 1rem;
    }
}