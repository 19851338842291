* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    /* background: #f4f4f4; */
    background: #d4fbff;
}

img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    vertical-align: bottom;
}