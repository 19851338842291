.productscreen {
    max-width: 1300px;
    margin: 1rem auto;
    display: flex;
}

.productscreen__left {
    display: flex;
    flex: 0.8;
}

.left__image {
    margin: 1rem;
    flex: 0.6;
    border-style: solid;
    border-width: 0.5px;
    border-radius: 4px;
    border-color: rgb(0, 9, 74);
}

.left__info {
    margin: 1rem;
    flex: 0.4;
    background: #fff;
    height: fit-content;
    font-size: 0.9rem;
    border-style: solid;
    border-width: 0.5px;
    border-radius: 4px;
    border-color: rgb(0, 9, 74);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
}

.left__name {
    font-weight: bold;
    font-size: 1.3rem;
}

.left__info > p,
.right__info > p {
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.left__info > p:last-child,
.right__info > p:last-child {
    border: none;
}

.productscreen__right {
    flex: 0.2;
}

.right__info {
    width: 250px;
    margin: 1rem;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
    border-style: solid;
    border-width: 0.5px;
    border-radius: 4px;
    border-color: rgb(0, 9, 74);
}

.right__info > p {
    padding: 1rem;
    font-size: 0.8rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.right__info > p > button {
    grid-column: 1/-1;
    padding: 10px 16px;
    background: #171717;
    color: #f4f4f4;
    border: 1px solid #171717;
    cursor: pointer;
}

.right__info select {
    padding: 10px 16px;
}

@media (max-width: 950px) {
    .productscreen {
        flex-direction: column;
    }

    .productscreen__left {
        flex-direction: column;
        flex: 1;
    }

    .left__image {
        flex: 1;
    }

    .left__info {
        flex: 1;
    }

    .productscreen__right {
        flex: 1;
        padding: 1rem;
    }

    .right__info {
        width: 100%;
        margin: 0;
    }
}